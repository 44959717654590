import {cloneElement, useContext, useEffect, useRef, useState} from 'react'
import {useForm} from "react-hook-form"
import {useLocation} from 'react-router'
import authService, {ProfileModel} from "src/services/auth.service"
import workshiftService, {WorkshiftModel} from "src/services/workshift.service"
import WorkshiftStart from "../workshift/workshift"
import {isNil} from "src/utils/isNil"
import {ConfigContext} from "src"
import 'antd/lib/tabs/index'
import './styles.scss'

interface HeaderConfig extends React.HTMLAttributes<HTMLDivElement> {
  children: JSX.Element,
  topchildren: JSX.Element,
}

export default function Header(config: HeaderConfig) {
  const form = useForm()
  const headerRef = useRef()
  const [profile, setProfile] = useState<ProfileModel>()
  const {settings} = useContext(ConfigContext)
  const [workshift, setWorkshift] = useState<WorkshiftModel>()
  const location = useLocation()

  useEffect(() => {
    const sub = authService.profile$.subscribe((profile) => {
      if (profile.points.length > 0) form.setValue('point', profile.points[0])
      form.setValue('is_superuser', profile.is_superuser)
      setProfile(profile)
    })
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = workshiftService.workshift$.subscribe(setWorkshift)
    return () => sub.unsubscribe()
  }, [])

  const useWorkshift = settings && settings.workshift
  const workshiftStarted = workshift && !isNil(workshift.start_at)
  const is_superuser = profile && profile.is_superuser

  const contentAccess = useWorkshift ? workshiftStarted || is_superuser : true

  const children = cloneElement(config.children, {ref: headerRef})

  return (
    <div {...config}>
      <div ref={headerRef} id="header" className="sticky top-0 bg-white flex flex-col w-full" style={{zIndex: 100}}>
        {config.topchildren}
        <div id="header-content" />
      </div>

      {document.getElementById("header-content") && (contentAccess || !location.pathname.startsWith('/orders') ? children : <WorkshiftStart />)}
    </div>
  )
}
