import FormSelectAsync, {FormSelectAsyncConfig} from "../../inputs/form-select-async.list"
import {Observable, firstValueFrom, map} from "rxjs"
import appService from "src/services/app.service"
import {RentalPointModel} from "src/models/manager/constants"
import {useTranslation} from "react-i18next"


type RemovalKeys = 'listOptions' | 'getValue' | 'getOptionLabel' | 'getOptionValue' | 'placeholder' | 'styles'
const rentalPoints: Observable<RentalPointModel[]> = appService.getConstant('RENTAL_POINTS')

export function SharedRentalPointSelect(config: Omit<FormSelectAsyncConfig<RentalPointModel>, RemovalKeys>) {
    const {t} = useTranslation()
    return <FormSelectAsync<RentalPointModel>
        {...config}
        listOptions={() => rentalPoints}
        placeholder={t('common.select.rental_point')}
        getValue={(val: number | number[]) => firstValueFrom(rentalPoints.pipe(map(list => Array.isArray(val) ? list.filter(o => val.includes(o.id)) : list.find(o => o.id === +val))))}
        getOptionLabel={(option: RentalPointModel) => (option ? String(option.name) : null)}
        getOptionValue={(option: RentalPointModel) => (option ? String(option.id) : null)}
    />
}