import {Outlet} from 'react-router-dom'
import {useContext} from 'react'
import HeaderContent from "src/components/header/header-content"
import {ConfigContext} from "src"

export default function DeliveryWrapperComponent() {
    const {settings} = useContext(ConfigContext)

    return settings && <>
        <Outlet context={settings} />
    </>
}
