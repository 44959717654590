import {App, ConfigProvider} from 'antd'
import ruRU from 'antd/locale/ru_RU'
import enUS from 'antd/locale/en_US'
import {initializeApp} from 'firebase/app'
import {createContext, useEffect, useState} from 'react'
import ReactDOM from 'react-dom/client'
import {Navigate, Outlet, RouteObject, RouterProvider, createBrowserRouter, useLocation, matchRoutes} from 'react-router-dom'
import {initializeFaro, createReactRouterV6DataOptions, ReactIntegration, getWebInstrumentations, withFaroRouterInstrumentation} from '@grafana/faro-react'
import {TracingInstrumentation} from '@grafana/faro-web-tracing'
import {ToastContainer} from 'react-toastify'
import {GlobalModal} from './components/modal/global/globalModal'
import modalService from "./components/modal/global/modal.service"
import AuthComponent from './pages/auth/auth'
import SchedulesListComponent from './pages/schedule'
import {ProductSetEditComponent} from "./pages/set/edit"
import ProductSetListComponent from './pages/set/list'
import {clientRoutes} from './routers/client'
import {configRoutes} from './routers/configuration'
import {inventoryRoutes} from './routers/inventory'
import {orderRoutes} from './routers/order'
import {settingRoutes} from "./routers/settings"
import {websiteRoutes} from "./routers/website"
import {Spotlight} from "./components/onboarding/spotlight"
import {I18nextProvider, useTranslation} from "react-i18next"
import {TenantSubscriptionModal} from "./components/modal/components/subscription"
import {CustomModal} from "./components/modal/global/customModal"
import {IntegrationComponent} from "./pages/integration"
import {SmsIntegrationComponent} from "./pages/integration/sms"
import {SettingConfig} from "./models/setting-config"
import subscriptionModalService from "./services/subscription.service"
import integrationService from "./services/integration"
import settingService from "./services/settings.service"
import moment from "moment-timezone"
import i18n from "./i18n"
import Main from './main'
import 'src/interceptor'
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/ru'
import './index.scss'
import {ManagerConstantMapModel, ManagerConstantModel} from "./models/manager/constants"
import appService from "./services/app.service"

moment.tz.setDefault("Asia/Tashkent")
moment.locale('ru')

const firebaseConfig = {
  apiKey: 'AIzaSyAhiRARLU6Tf43VJyeQe1gLvGORkIwJxj0',
  authDomain: 'yume-admin.firebaseapp.com',
  projectId: 'yume-admin',
  storageBucket: 'yume-admin.appspot.com',
  messagingSenderId: '195215475313',
  appId: '1:195215475313:web:458baeb90d54a23cd091d0',
}

initializeApp(firebaseConfig)

// initializeFaro({
//   url: 'https://faro-collector-prod-au-southeast-0.grafana.net/collect/459ef04f5cf816540fb573c9d45b3bad',
//   // url: 'https://faro-collector-prod-au-southeast-0.grafana.net/collect/2afe875e41014401a64d8b39932af7fe',
//   app: {
//     name: 'yume.cloud',
//     version: '1.0.0',
//     environment: 'production'
//   },

//   instrumentations: [
//     ...getWebInstrumentations(),
//     new TracingInstrumentation(),
//     new ReactIntegration({
//       router: createReactRouterV6DataOptions({
//         matchRoutes,
//       }),
//     })
//   ],
// })

const root = ReactDOM.createRoot(document.getElementById('root'))

interface ClientContextModel {
  settings: SettingConfig
  constants: ManagerConstantModel
  constantsMap: ManagerConstantMapModel
}

export const ConfigContext = createContext<ClientContextModel>(undefined)

function Application({children}) {
  const location = useLocation()
  const [settings, setSettings] = useState<SettingConfig>()
  const [constants, setConstants] = useState<ManagerConstantModel>()
  const [constantsMap, setConstantsMap] = useState<ManagerConstantMapModel>()
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => modalService.closeModal(), [location.pathname])

  useEffect(() => {
    integrationService.listIntegrations()
    const sub = subscriptionModalService.show$.subscribe(setShow)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = settingService.config$.subscribe(setSettings)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = appService.constants$.subscribe((c) => {
      setConstants(c)
      setConstantsMap({
        CLIENT_TICKS: Object.fromEntries(c.CLIENT_TICKS.map(o => [o.id, o])),
        CLIENT_ATTRACTION_METHOD: Object.fromEntries(c.CLIENT_ATTRACTION_METHOD.map(o => [o.id, o])),
        CLIENT_TYPE: Object.fromEntries(c.CLIENT_TYPE.map(o => [o.id, o])),
        SERVICE_TYPE: Object.fromEntries(c.SERVICE_TYPE.map(o => [o.id, o])),
        INVENTORY_GROUP_TYPE: Object.fromEntries(c.INVENTORY_GROUP_TYPE.map(o => [o.id, o])),
        INVENTORY_CATEGORIES: Object.fromEntries(c.INVENTORY_CATEGORIES.map(o => [o.id, o])),
        INVENTORY_STATUS: Object.fromEntries(c.INVENTORY_STATUS.map(o => [o.id, o])),
        INVENTORY_STATE_STATUS: Object.fromEntries(c.INVENTORY_STATE_STATUS.map(o => [o.id, o])),
        TARIF_TIME_PERIODS: Object.fromEntries(c.TARIF_TIME_PERIODS.map(o => [o.id, o])),
        ORDER_REQUEST_STATUS: Object.fromEntries(c.ORDER_REQUEST_STATUS.map(o => [o.id, o])),
        ORDER_PAYMENT_STATUS: Object.fromEntries(c.ORDER_PAYMENT_STATUS.map(o => [o.id, o])),
        PAYMENT_TYPES: Object.fromEntries(c.PAYMENT_TYPES.map(o => [o.id, o])),
        RENTAL_POINTS: Object.fromEntries(c.RENTAL_POINTS.map(o => [o.id, o])),
        PASSPORT_ISSUERS: Object.fromEntries(c.PASSPORT_ISSUERS.map(o => [o.id, o])),
        DISCOUNT_TYPE: Object.fromEntries(c.DISCOUNT_TYPE.map(o => [o.id, o])),
        DISCOUNT_CALCULATION_TYPE: Object.fromEntries(c.DISCOUNT_CALCULATION_TYPE.map(o => [o.id, o])),
      })
    })
    return () => sub.unsubscribe()
  }, [])

  return <ConfigContext.Provider value={{settings, constants, constantsMap}}>
    {children}
    <CustomModal dialogClassName="subscription-modal" show={show} onHide={() => setShow(false)}>
      <TenantSubscriptionModal />
    </CustomModal>
  </ConfigContext.Provider>
}

export const routes: RouteObject[] = [
  {
    path: '',
    element: (
      <Application>
        <Outlet />
        <Spotlight />
        <GlobalModal />
        <ToastContainer
          position="bottom-right"
          autoClose={3500}
          hideProgressBar={true}
          newestOnTop={true}
          draggable={false}
          theme="light"
        />
      </Application>
    ),
    children: [
      {
        path: '',
        element: (
          <Main>
            <Outlet />
          </Main>
        ),
        errorElement: (
          <Main>
            <div className="flex items-center justify-center p-5">
              <div className="mb-0 text-2xl font-medium p-5">Error</div>
            </div>
          </Main>
        ),
        children: [
          {path: '', element: <Navigate to="orders" relative="route" replace={false} />},
          {path: 'calendar', element: <SchedulesListComponent />},

          // sets
          {path: 'inventory-sets', element: <ProductSetListComponent />},
          {path: 'inventory-sets/add', element: <ProductSetEditComponent mode="create" />},
          {path: 'inventory-sets/:id', element: <ProductSetEditComponent mode="edit" />},

          {path: 'integrations', element: <IntegrationComponent />},
          {path: 'integrations/sms', element: <SmsIntegrationComponent />},
          {path: 'integrations/*', element: <>not found</>},

          ...websiteRoutes,
          ...orderRoutes,
          ...configRoutes,
          ...settingRoutes,
          ...clientRoutes,
          ...inventoryRoutes,
        ],
      },
      {
        path: 'auth',
        element: <AuthComponent />,
      },
    ],
  },
  {
    path: "*", element: ""
  }
]

const router = withFaroRouterInstrumentation(createBrowserRouter(routes))

const antLocale = {
  'ru': ruRU,
  'en': enUS
}

function ConfigWrapper() {
  const {i18n} = useTranslation()

  return <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#000000',
        colorPrimaryBg: '#e2e2e2',
        fontSize: 14,
        borderRadius: 8,
        colorInfo: "#000000",
        lineHeight: 1.32,
        fontFamily: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;",
      },
      components: {
        Table: {},
        Checkbox: {
          controlInteractiveSize: 24,
          borderRadius: 6,
          borderRadiusLG: 6,
          borderRadiusXS: 6,
          borderRadiusSM: 6,
          colorBorder: 'var(--color-gray-200)',
          colorBgContainer: 'var(--color-white)',
          colorBgContainerDisabled: 'var(--color-gray-100)',
        },
      },
    }}
    locale={antLocale[i18n.language] || ruRU}
  >
    <App>
      <RouterProvider router={router} />
    </App>
  </ConfigProvider>
}

root.render(
  <I18nextProvider i18n={i18n}>
    <ConfigWrapper />
  </I18nextProvider>
)
