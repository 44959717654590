import moment from "moment"
import {useContext, useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {AsyncRentalPoint} from "src/abstract/async-rental-point"
import rentalPointService from "src/components/modal/global/rental-point.service"
import {FormControlDaterange} from 'src/components/shared/inputs/form-control-daterange'
import {FormControl} from 'src/components/shared/inputs/form-control'
import {useCurrentRoute} from 'src/hooks/useCurrentRoute'
import {useDebounce} from 'src/hooks/useDebounce'
import {EMPTY_LIST, ListModel, ListParams} from 'src/models/common'
import {OrderRequestListModel} from 'src/models/manager/order/order-request-list.model'
import ordersService from 'src/services/order-requests/order.service'
import {RentFilter} from "../../settings/settings-rent"
import OrderListTabComponent from "./order-tab.grid"
import OrderListTableTabComponent from "./order-tab.table"
import {SharedClientTypeSelect} from "src/components/shared/components/select/client-type"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"
import OrderListAmountComponent from "./order-amount"
import {ConfigContext} from "src"
import './orders.scss'

interface OrderParams extends ListParams {
    search: string
    start_at: string
    end_at: string
    client__signed: boolean
    client__type: string
    grant: boolean
    status: number
}

interface OrderingModel {
    order: string
    label: string
}

const date_filter: Record<RentFilter, {start_at: string, end_at: string}> = {
    DAY: {start_at: moment().startOf('D').format('YYYY-MM-DD'), end_at: moment().startOf('D').add(1, 'days').format('YYYY-MM-DD')},
    WEEK: {start_at: moment().startOf('W').format('YYYY-MM-DD'), end_at: moment().startOf('W').add(1, 'week').format('YYYY-MM-DD')},
    MONTH: {start_at: moment().add(-0.5, 'month').format('YYYY-MM-DD'), end_at: moment().add(0.5, 'month').format('YYYY-MM-DD')},
    QUARTILE: {start_at: moment().add(-1.5, 'month').format('YYYY-MM-DD'), end_at: moment().add(1.5, 'month').format('YYYY-MM-DD')},
    YEAR: {start_at: moment().add(-6, 'month').format('YYYY-MM-DD'), end_at: moment().add(6, 'month').format('YYYY-MM-DD')},
}

export default function OrderListTabFilterComponent({filter}: {filter: any}) {
    const [list, setList] = useState<ListModel<OrderRequestListModel>>(EMPTY_LIST)
    const [type, setType] = useState<'table' | 'grid'>((localStorage.getItem('order-view') as 'table' | 'grid') || 'grid')
    const {settings} = useContext(ConfigContext)

    const {t} = useTranslation()
    const _defaultConfig = {page: 1, pageSize: 12, ordering: '-rent_start', rental_point: rentalPointService.filterPoint}
    const defaultConfig = settings ? {..._defaultConfig, ...date_filter[settings.order_default_filter]} : _defaultConfig
    const form = useForm<OrderParams>({defaultValues: defaultConfig})
    const {reset, setValue, watch} = form
    const values = watch()
    const activeRoute = useCurrentRoute()

    const orderingOptions: OrderingModel[] = [
        {order: '-rent_start', label: t('orders.filter.order_rent_start_desc')},
        {order: 'rent_start', label: t('orders.filter.order_rent_start_asc')},
    ]

    const searchDebounce = useDebounce(watch('search', ''), 500)

    const onLoad = async (params: any): Promise<void> =>
        ordersService.list({...params, ...filter}).then(res => {
            if (type === 'table') setList(res)
            if (type === 'grid') setList(prevList => (params.page === 1 ? res : {...res, results: [...prevList.results, ...res.results]}))
        }).catch(e => e.response.status === 404 && setValue('page', 1))

    const toggleTable = () => setType(() => {
        localStorage.setItem('order-view', 'table')
        return 'table'
    })
    const toggleGrid = () => setType(() => {
        localStorage.setItem('order-view', 'grid')
        return 'grid'
    })

    useEffect(() => reset({...values, page: 1, search: searchDebounce}), [searchDebounce])

    useEffect(() => {
        onLoad(values)
        const sub = watch((params, {name}) => {
            if (name !== 'search') onLoad(params)
        })
        return () => {
            sub.unsubscribe()
            setList(EMPTY_LIST)
        }
    }, [activeRoute, type])

    return (
        <FormProvider {...form}>
            <div className="flex justify-start gap-2 mb-3">
                <FormControlDaterange
                    allowClear={true}
                    className="col form-control"
                    name_start="start_at"
                    name_end="end_at"
                />
                <AsyncRentalPoint name='rental_point' />
                <SharedClientTypeSelect
                    placeholder={t('common.select.placeholder.client_type')}
                    name="client__type"
                    isClearable={true}
                    isSearchable={false}
                />

                <div className="flex-1"></div>
                <FormControl
                    icon="search"
                    name="search"
                    rootclassname="col-3"
                    className="form-control"
                    placeholder={t('common.input.search')}
                />
            </div>

            <div className="flex items-stretch justify-between mb-3">
                <div className="flex gap-2">
                    {orderingOptions.map(order => (
                        <button
                            key={order.order}
                            onClick={() => reset({...values, ordering: order.order, page: 1})}
                            className={order.order === values.ordering ? 'btn btn-card' : 'btn'}
                        >
                            {order.label}
                        </button>
                    ))}
                </div>

                <div className="flex gap-2 items-stretch">
                    {type === 'grid' && <span className="color-black font-medium align-self-center mx-2">Всего: {list.count}</span>}
                    <OrderListAmountComponent filter={filter} />
                    <div className={`btn btn-card font-medium ${type === 'table' ? 'btn-primary btn-color-white' : ''}`} onClick={toggleTable}>
                        <Icon className="text-[21px]" icon="menu" />
                    </div>
                    <div className={`btn btn-card font-medium ${type === 'grid' ? 'btn-primary btn-color-white' : ''}`} onClick={toggleGrid}>
                        <Icon className="text-[21px]" icon="dataset" />
                    </div>
                </div>
            </div>

            {type === 'grid' && <OrderListTabComponent list={list} />}
            {type === 'table' && <OrderListTableTabComponent list={list} />}

        </FormProvider>
    )
}
