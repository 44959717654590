import {Popover, PopoverProps, Tooltip} from "antd"
import React, {SyntheticEvent} from "react"
import {Link} from "react-router-dom"
import Icon from "../components/material-icon"
import {useTranslation} from "react-i18next"

interface OptionProps {
    key: string
    title: string | JSX.Element
    icon?: string
    onClick?: (e?: SyntheticEvent) => void
    hidden?: boolean
    disabled?: boolean
    connected?: boolean
    is_dev?: boolean
}

function PopoverButton({title, icon, onClick = () => { }, disabled = false, is_dev = false, connected = true}: OptionProps) {
    const {t} = useTranslation()

    return <div className="flex items-center gap-1 w-full border-b border-gray-100 last:border-none">
        <button disabled={disabled || !connected} className="btn btn-white justify-start gap-2 py-2 flex-1" onClick={onClick}>
            {icon && <Icon className="text-lg" icon={icon} />}
            {title}
        </button>
        {is_dev && (
            <>
                <div className="vr my-2 color-gray-400" />

                <Tooltip
                    title={(
                        <div className="flex gap-2 flex-col">
                            <span className="text-center">Для подключения данного функционала свяжитесь с менеджером</span>
                            <a href="https://wa.me/+7779479990" target="_blank" className="btn btn-white px-3 py-2" rel="noreferrer">{t('banner.link')}</a>
                        </div>
                    )}
                    zIndex={10001}
                >
                    <Icon icon="info" className="btn py-0" />
                </Tooltip>
            </>
        )}
        {!connected && (
            <>
                <div className="vr my-2 color-gray-400" />

                <Tooltip
                    title={(
                        <div className="flex gap-2 flex-col">
                            <span className="text-center">Для подключения данного функционала подключите интеграцию</span>
                            <Link to="/integrations" className="btn btn-white px-3 py-2">Перейти</Link>
                        </div>
                    )}
                    zIndex={10001}
                >
                    <Icon icon="info" className="btn py-0" />
                </Tooltip>
            </>
        )}
    </div>
}

export interface PopoverOptionsProps extends PopoverProps {
    options: OptionProps[]
}

const _PopoverOptions = React.forwardRef<any, PopoverOptionsProps>((props, ref) => {
    return <Popover
        ref={ref}
        {...props}
        content={(
            <div className="flex flex-col items-stretch max-h-80">
                {props.options.map((option) => <PopoverButton key={option.key} {...option} />)}
            </div>
        )}
    />
})

export default _PopoverOptions