import {RentFilter} from "src/pages/settings/settings-rent"
import {OrderStatusColor} from 'src/components/shared/constants'

export type CustomFieldType =
  'string'
  | 'html'
  | 'integer'
  | 'decimal'
  | 'boolean'
  | 'date'
  | 'datetime'
  | 'color'
  | 'client'
  | 'inventory'
  | 'product'
  | 'set'

export type CustomFieldOption = Record<string, Array<{[key: string]: string | number, cnt: number}>>

export const optionlessFieldTypes: CustomFieldType[] = ['boolean', 'color', 'client', 'inventory', 'product', 'set', 'html']

export const defaultCustomField: CustomFieldModel = {
  inventory_group: [],
  inventory_set: [],
  inventory: [],
  service: [],
  category: [],
  client: [],
  client_document: [],
  order: [],
  order_inventory: [],
  order_service: [],
}

export interface CustomField {
  name: string
  label: string
  required: boolean
  table: boolean
  filter: boolean
  type: CustomFieldType
  options: any[]
  defaultValue: string
}

export interface CustomFieldModel {
  inventory_group: CustomField[]
  inventory_set: CustomField[]
  inventory: CustomField[]
  service: CustomField[]
  category: CustomField[]
  client: CustomField[]
  client_document: CustomField[]
  order: CustomField[]
  order_inventory: CustomField[]
  order_service: CustomField[]
}

export interface PenaltyOption {
  name: string
  duration: string
  // value: number
}

export type PriceRounding = 'none' | 'default' | 'up' | 'down'

export interface SettingConfig extends Record<OrderStatusColor, string> {
  name: string
  address: string
  currency: string
  type: string
  reservation: boolean
  order_duration: string
  min_order_duration: string
  max_order_duration: string
  penalty: boolean
  penalty_after: boolean
  penalty_default: string
  creation_percent: number
  reservation_percent: number
  issue_percent: number
  recievement_percent: number
  single_issue: boolean
  single_recievement: boolean
  order_deposit: boolean
  workshift: boolean
  price_rounding: PriceRounding
  smsc_login: string
  smsc_password: string
  show_client_document: boolean
  order_default_filter: RentFilter
  custom_fields: CustomFieldModel
  penalty_options: PenaltyOption[]
  bonus_default_amount: number
  bonus_default_percent: number
  bonus_default_usage: number
  rent_inventory_priority: 'COMPANY' | 'SUBLEASE' | 'ALL',
  metrics_order_split: 'created_at' | 'rent_start' | 'rent_fact_start' | 'rent_end' | 'rent_fact_end',
  metrics_order_statuses: number[]
  metrics_order_payment_statuses: number[]
  rent_product_types: ('inventory' | 'group' | 'sale')[]
  tax: boolean
  tax_included: boolean
  tax_percent: number
  tg_channel_link: string
  tg_channel_message_template: string
  phone_format: string
}
