import {forwardRef, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {Aside} from './components/aside/aside'
import Header from './components/header'
import HeaderPeriodBanner from "./components/header-banner"
import rentalPointService from "./components/modal/global/rental-point.service"
import {Spinner} from './components/shared/spinner/spinner'
import authService from "./services/auth.service"
import loadingService from "./services/loading.service"
import TourWrapper from "./components/onboarding"
import clsx from "clsx"
import {use100vh} from "react-div-100vh"
import {ConfigContext} from "src"
import './App.scss'

const Children = forwardRef(({children}: {children: JSX.Element}, ref) => {
  const scrollToRef = (_ref: any) => _ref.current?.scroll({top: 0, behavior: 'smooth'})
  const location = useLocation()
  const contentRef = useRef(null)

  const noPadding = useMemo(() => ['/profile', '/config', '/settings', '/documents'].some(link => location.pathname.startsWith(link)), [location.pathname])

  const scroll = () => scrollToRef(contentRef)
  useEffect(() => scroll(), [location.pathname])

  return (
    <div className={clsx("relative flex-1 w-full h-full bg-bg-light overflow-x-scroll", noPadding ? 'p-0' : 'p-4')} ref={contentRef}>
      {children}
    </div>
  )
})

export default function Main({children}: {children: JSX.Element}) {
  const navigate = useNavigate()
  const height = use100vh()
  const {constants} = useContext(ConfigContext)
  const [loading, setLoading] = useState<string[]>([])

  const [smallMenu, setSmallMenu] = useState(() => {
    if (localStorage.getItem('aside-menu')) return localStorage.getItem('aside-menu') === 'true'
    return false
  })

  const onAside = () => setSmallMenu(p => {
    localStorage.setItem('aside-menu', String(!p))
    return !p
  })

  useEffect(() => {
    const loadingSub = loadingService.loading$.subscribe(setLoading)
    return () => loadingSub.unsubscribe()
  }, [])

  useEffect(() => {
    if (!constants) return
    const rentalPoint = authService.profile$.subscribe((profile) => {
      if (profile.points.length > 0) rentalPointService.setPoint(constants.RENTAL_POINTS[profile.points[0]])
    })
    return () => rentalPoint.unsubscribe()
  }, [constants])

  useEffect(() => {
    const sub = authService.logged$.subscribe(logged => {
      if (!logged) navigate('/auth')
    })
    return () => sub.unsubscribe()
  }, [navigate])

  return (
    constants && (
      <>
        <div className={clsx('wrapper big-menu', smallMenu && 'small-menu')} id="vhContent">
          <Aside collapse={smallMenu} onCollapse={onAside} />
          <div className="relative wrapper-content overflow-y-scroll" style={{height}}>
            <Header className="relative right-side" topchildren={<HeaderPeriodBanner />}>
              <Children>{children}</Children>
            </Header>
            <TourWrapper />
          </div>
        </div>

        {loading.length > 0 && <Spinner />}
      </>
    )
  )
}
