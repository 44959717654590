import axios from 'axios'
import {CrudInterface} from '../../interfaces/crud.interface'
import {ListModel} from '../../models/common'
import {ApiAbstract} from '../../abstract/api.abstract'
import {InventoryModel} from '../../models/manager/inventory/inventory.model'
import {InventoryGeneralModel} from 'src/models/manager/inventory/inventory-general.model'
import {InventoryGeneralStatisticsModel} from 'src/models/manager/inventory/inventory-general-statistics.model'
import {OrderRequestInventoryScheduleModel} from "src/models/manager/order/order-request-inventory"

export class ManageInventoriesService extends ApiAbstract implements CrudInterface<InventoryModel> {
  constructor() {
    super('v1/crm/inventories')
  }

  public async listUnavailables(params?: any): Promise<OrderRequestInventoryScheduleModel[]> {
    const response = await axios.get(this.getUrl('unavailables'), {params})
    return response.data
  }
  public async general(params?): Promise<InventoryGeneralModel> {
    const response = await axios.get(this.getUrl('general'), {params})
    return response.data
  }
  public async list(params?: any): Promise<ListModel<InventoryModel>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async get(id: number): Promise<InventoryModel> {
    const response = await axios.get(this.getUrl(id))
    return response.data
  }
  public async getGeneral(id: number): Promise<InventoryGeneralStatisticsModel> {
    const response = await axios.get(this.getUrl(`${id}/general`))
    return response.data
  }
  public async transfer(payload: any): Promise<any> {
    const response = await axios.post(this.getUrl('transfer'), payload)
    return response.data
  }
  public async sell(payload: any): Promise<any> {
    const response = await axios.post(this.getUrl('sell'), payload)
    return response.data
  }
  public async bulk_update(payload: any): Promise<any> {
    const response = await axios.post(this.getUrl('bulk_update'), payload)
    return response.data
  }
  public async post(payload: any): Promise<InventoryModel> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }
  public async patch(id: number, payload: any): Promise<InventoryModel> {
    const response = await axios.patch(this.getUrl(id), payload)
    return response.data
  }
  public async delete(id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(id))
    return response.data
  }
  public async archive(id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(`${id}/archive`))
    return response.data
  }
  public async importFile(file: File): Promise<void> {
    const body = new FormData()
    body.append('file', file)
    const response = await axios.post(this.getUrl('import'), body)
    return response.data
  }
}
const inventoriesService = new ManageInventoriesService()
export default inventoriesService
