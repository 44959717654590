import {EditorConfig} from "ckeditor5-custom-build/build/ckeditor"
import {CustomFieldModel} from "src/models/setting-config"

const colors = [
    {color: "#000000", label: "Black"},
    {color: "#4d4d4d", label: "Dim grey"},
    {color: "#999999", label: "Grey"},
    {color: "#e6e6e6", label: "Light grey"},
    {color: "#ffffff", label: "White", hasBorder: true},
    {color: "#e64c4c", label: "Red"},
    {color: "#e6994c", label: "Orange"},
    {color: "#e6e64c", label: "Yellow"},
    {color: "#99e64c", label: "Light green"},
    {color: "#4ce64c", label: "Green"},
    {color: "#4ce699", label: "Aquamarine"},
    {color: "#4ce6e6", label: "Turquoise"},
    {color: "#4c99e6", label: "Light blue"},
    {color: "#4c4ce6", label: "Blue"},
    {color: "#994ce6", label: "Purple"},
]

export const pageSizes = {
    "297x420": {name: "A3", margin: "2em"},
    "210x297": {name: "A4", margin: "2em"},
    "148x210": {name: "A5", margin: "2em"},
    "105x148": {name: "A6", margin: "1em"},
    "80x120": {name: "Чек (lg)", margin: "0em"},
    "60x120": {name: "Чек (md)", margin: "0em"},
}

export const placeholderRegex = /<span class="placeholder" data="([^"]+)">([^<]+)<\/span>/g

export const placeholderKeys = {
    "date": "Дата",
    "datetime": "Дата со временем",
    "time": "Время",
    "user": "Имя менеджера",
    "proxy_user": "Владелец доверенности",
    "proxy_num": "Номер доверенности",
    "proxy_date": "Дата доверенности",
    "rent_id": "№ аренды",
    "rent_unique_id": "Уникальный номер",
    "rent_start": "Начало аренды",
    "rent_start_fact": "Фактическое начало аренды",
    "rent_end": "Конец аренды",
    "rent_end_fact": "Фактический конец аренды",
    "rent_price": "Сумма аренды без учета скидок",
    "rent_price_str": "Сумма аренды без учета скидок (текстом)",
    "rent_price_discount": "Сумма аренды",
    "rent_price_discount_str": "Сумма аренды (текстом)",
    "rent_paid_amount": "Сумма оплаты",
    "rent_paid_amount_str": "Сумма оплаты (текстом)",
    "rent_price_inv": "Сумма инвентарей",
    "rent_price_inv_str": "Сумма инвентарей (текстом)",
    "rent_price_service": "Сумма услуг",
    "rent_price_service_str": "Сумма услуг (текстом)",
    "rent_price_delivery": "Сумма доставки",
    "rent_price_delivery_str": "Сумма доставки (текстом)",
    "rent_price_daily": "Сумма аренды за день",
    "rent_price_daily_str": "Сумма аренды за день (текстом)",
    "rent_debt": "Сумма долга",
    "rent_debt_str": "Сумма долга (текстом)",
    "rent_discount": "Сумма скидок",
    "rent_discount_str": "Сумма скидок (текстом)",
    "rent_discount_inv": "Сумма скидок инвентарей",
    "rent_discount_inv_str": "Сумма скидок инвентарей (текстом)",
    "rent_discount_service": "Сумма скидок услуг",
    "rent_discount_service_str": "Сумма скидок услуг (текстом)",
    "rent_penalty": "Сумма штрафа",
    "rent_penalty_str": "Сумма штрафа (текстом)",
    "rent_created_by": "Автор аренды",
    "rent_created_at": "Дата создания",
    "rent_reserved_at": "Дата бронирования",
    "cnt_inventories": "Количество продуктов в аренде",
    "cnt_services": "Количество услуг в аренде",
    "sum_inventory_buy_brice": "Стоимость всех инвентарей",
    "sum_inventory_buy_brice_str": "Стоимость всех инвентарей (текстом)",
    "client_id": "№ клиента",
    "client_name": "Имя",
    "client_phone": "Телефон",
    "client_email": "Email",
    "client_agreement_id": "Номер договора",
    "client_sign_date": "Дата подписания договора",
    "client_discount": "Персональная скидка",
    "client_type": "Тип клиента",
    "client_ind_iin": "ИИН",
    "client_ind_num": "Номер",
    "client_ind_date": "Дата выдачи",
    "client_ind_date_end": "Срок окончания",
    "client_ind_birth_date": "День рождение",
    "client_ind_issuer": "Выдан",
    "client_leg_bin": "БИН",
    "client_leg_address": "Адрес",
    "client_leg_director": "Директор",
    "client_leg_iban": "IBAN",
    "client_leg_bik": "БИК",
    "client_leg_bank": "Банк",
    "inv_index": "Индекс инвентаря",
    "inv_id": "№ инвентаря",
    "inv_name": "Название инвентаря",
    "inv_unique_id": "Артикул",
    "inv_duration": "Длительнось",
    "inv_duration_fact": "Фактическая длительность",
    "inv_discount": "Сумма скидки инвентаря",
    "inv_discount_str": "Сумма скидки инвентаря (текстом)",
    "inv_start": "Начало аренды инвентаря",
    "inv_start_fact": "Фактическое начало аренды инвентаря",
    "inv_end": "Конец аренды инвентаря",
    "inv_end_fact": "Фактический конец аренды инвентаря",
    "inv_price": "Сумма без учета скидки инвентаря",
    "inv_price_str": "Сумма без учета скидки инвентаря (текстом)",
    "inv_price_discount": "Сумма инвентаря",
    "inv_price_discount_str": "Сумма инвентаря (текстом)",
    "inv_penalty": "Сумма штрафа инвентаря",
    "inv_penalty_str": "Сумма штрафа инвентаря (текстом)",
    "inv_debt": "Сумма долга за инвентарь",
    "inv_tarif_price": "Цена тарифа",
    "inv_tarif_duration": "Длительность тарифа",
    "inv_state": "Состояние инвентаря",
    "inv_inventory_category": "Категория",
    "inv_inventory_buy_price": "Закупочная стоимость",
    "inv_inventory_buy_price_str": "Закупочная стоимость (текстом)",
    "inv_inventory_buy_date": "Дата покупки",
    "inv_inventory_point_name": "Название точки проката",
    "inv_inventory_point_address": "Адрес точки проката",
    "service_index": "Индекс услуги",
    "service_id": "№ услуги",
    "service_name": "Название услуги",
    "service_price": "Сумма без учета скидки услуги",
    "service_price_str": "Сумма без учета скидки услуги (текстом)",
    "service_price_discount": "Сумма услуг",
    "service_price_discount_str": "Сумма услуг (текстом)",
    "service_extra_delivery_address": "Адрес доставки",
    "service_info": "Комментарии",
    "service_tarif_price": "Цена тарифа",
    "service_tarif_price_str": "Цена тарифа (текстом)",
    "service_tarif_duration": "Длительность тарифа",
    "service_worker": "Исполнитель",
}

export const editorPlaceholder = (config: CustomFieldModel) => {
    return [
        {
            key: 'user',
            label: 'Общее',
            type: 'group',
            children: [
                {label: placeholderKeys['user'], key: 'user'},
                {label: placeholderKeys['proxy_user'], key: 'proxy_user'},
                {label: placeholderKeys['proxy_num'], key: 'proxy_num'},
                {label: placeholderKeys['proxy_date'], key: 'proxy_date'},
                {label: placeholderKeys['date'], key: 'date'},
                {label: placeholderKeys['datetime'], key: 'datetime'},
                {label: placeholderKeys['time'], key: 'time'},
            ],
        },
        {
            key: 'rent',
            label: 'Аренда',
            children: [
                {label: placeholderKeys['rent_id'], key: 'rent_id'},
                {label: placeholderKeys['rent_unique_id'], key: 'rent_unique_id'},
                {label: placeholderKeys['rent_start'], key: 'rent_start'},
                {label: placeholderKeys['rent_start_fact'], key: 'rent_start_fact'},
                {label: placeholderKeys['rent_end'], key: 'rent_end'},
                {label: placeholderKeys['rent_end_fact'], key: 'rent_end_fact'},
                {label: placeholderKeys['rent_price'], key: 'rent_price'},
                {label: placeholderKeys['rent_price_str'], key: 'rent_price_str'},
                {label: placeholderKeys['rent_price_discount'], key: 'rent_price_discount'},
                {label: placeholderKeys['rent_price_discount_str'], key: 'rent_price_discount_str'},
                {label: placeholderKeys['rent_price_inv'], key: 'rent_price_inv'},
                {label: placeholderKeys['rent_price_inv_str'], key: 'rent_price_inv_str'},
                {label: placeholderKeys['rent_price_service'], key: 'rent_price_service'},
                {label: placeholderKeys['rent_price_service_str'], key: 'rent_price_service_str'},
                {label: placeholderKeys['rent_price_delivery'], key: 'rent_price_delivery'},
                {label: placeholderKeys['rent_price_delivery_str'], key: 'rent_price_delivery_str'},
                {label: placeholderKeys['rent_price_daily'], key: 'rent_price_daily'},
                {label: placeholderKeys['rent_price_daily_str'], key: 'rent_price_daily_str'},
                {label: placeholderKeys['rent_paid_amount'], key: 'rent_paid_amount'},
                {label: placeholderKeys['rent_paid_amount_str'], key: 'rent_paid_amount_str'},
                {label: placeholderKeys['rent_debt'], key: 'rent_debt'},
                {label: placeholderKeys['rent_debt_str'], key: 'rent_debt_str'},
                {label: placeholderKeys['rent_discount'], key: 'rent_discount'},
                {label: placeholderKeys['rent_discount_str'], key: 'rent_discount_str'},
                {label: placeholderKeys['rent_discount_inv'], key: 'rent_discount_inv'},
                {label: placeholderKeys['rent_discount_inv_str'], key: 'rent_discount_inv_str'},
                {label: placeholderKeys['rent_discount_service'], key: 'rent_discount_service'},
                {label: placeholderKeys['rent_discount_service_str'], key: 'rent_discount_service_str'},
                {label: placeholderKeys['rent_penalty'], key: 'rent_penalty'},
                {label: placeholderKeys['rent_penalty_str'], key: 'rent_penalty_str'},
                {label: placeholderKeys['rent_created_by'], key: 'rent_created_by'},
                {label: placeholderKeys['rent_created_at'], key: 'rent_created_at'},
                {label: placeholderKeys['rent_reserved_at'], key: 'rent_reserved_at'},
                {label: placeholderKeys['cnt_inventories'], key: 'cnt_inventories'},
                {label: placeholderKeys['cnt_services'], key: 'cnt_services'},
                {label: placeholderKeys['sum_inventory_buy_brice'], key: 'sum_inventory_buy_brice'},
                {label: placeholderKeys['sum_inventory_buy_brice_str'], key: 'sum_inventory_buy_brice_str'},

                ...config.order.map(field => ({label: field.label, key: field.name}))
            ],
        },
        {
            label: 'Клиент',
            key: 'Клиент',
            children: [
                {label: placeholderKeys['client_id'], key: 'client_id'},
                {label: placeholderKeys['client_name'], key: 'client_name'},
                {label: placeholderKeys['client_phone'], key: 'client_phone'},
                {label: placeholderKeys['client_email'], key: 'client_email'},
                {label: placeholderKeys['client_agreement_id'], key: 'client_agreement_id'},
                {label: placeholderKeys['client_sign_date'], key: 'client_sign_date'},
                {label: placeholderKeys['client_discount'], key: 'client_discount'},
                {label: placeholderKeys['client_type'], key: 'client_type'},
                ...config.client.map(field => ({label: field.label, key: field.name})),
                {
                    label: 'Физ. Лицо',
                    key: 'Физ. Лицо',
                    children: [
                        {label: placeholderKeys['client_ind_iin'], key: 'client_ind_iin'},
                        {label: placeholderKeys['client_ind_num'], key: 'client_ind_num'},
                        {label: placeholderKeys['client_ind_date'], key: 'client_ind_date'},
                        {label: placeholderKeys['client_ind_date_end'], key: 'client_ind_date_end'},
                        {label: placeholderKeys['client_ind_birth_date'], key: 'client_ind_birth_date'},
                        {label: placeholderKeys['client_ind_issuer'], key: 'client_ind_issuer'},
                    ],
                },
                {
                    label: 'Юр. Лицо',
                    key: 'Юр. Лицо',
                    children: [
                        {label: placeholderKeys['client_leg_bin'], key: 'client_leg_bin'},
                        {label: placeholderKeys['client_leg_address'], key: 'client_leg_address'},
                        {label: placeholderKeys['client_leg_director'], key: 'client_leg_director'},
                        {label: placeholderKeys['client_leg_iban'], key: 'client_leg_iban'},
                        {label: placeholderKeys['client_leg_bik'], key: 'client_leg_bik'},
                        {label: placeholderKeys['client_leg_bank'], key: 'client_leg_bank'},
                    ],
                },
            ],
        },
        {
            key: 'inventory',
            label: 'Инвентари',
            children: [
                {label: placeholderKeys['inv_index'], key: 'inv_index', tag: 'table'},
                {label: placeholderKeys['inv_id'], key: 'inv_id', tag: 'table'},
                {label: placeholderKeys['inv_name'], key: 'inv_name', tag: 'table'},
                {label: placeholderKeys['inv_unique_id'], key: 'inv_unique_id', tag: 'table'},
                {label: placeholderKeys['inv_duration'], key: 'inv_duration', tag: 'table'},
                {label: placeholderKeys['inv_duration_fact'], key: 'inv_duration_fact', tag: 'table'},
                {label: placeholderKeys['inv_discount'], key: 'inv_discount', tag: 'table'},
                {label: placeholderKeys['inv_discount_str'], key: 'inv_discount_str', tag: 'table'},
                {label: placeholderKeys['inv_start'], key: 'inv_start', tag: 'table'},
                {label: placeholderKeys['inv_start_fact'], key: 'inv_start_fact', tag: 'table'},
                {label: placeholderKeys['inv_end'], key: 'inv_end', tag: 'table'},
                {label: placeholderKeys['inv_end_fact'], key: 'inv_end_fact', tag: 'table'},
                {label: placeholderKeys['inv_price'], key: 'inv_price', tag: 'table'},
                {label: placeholderKeys['inv_price_discount'], key: 'inv_price_discount', tag: 'table'},
                {label: placeholderKeys['inv_penalty'], key: 'inv_penalty', tag: 'table'},
                {label: placeholderKeys['inv_debt'], key: 'inv_debt', tag: 'table'},
                {label: placeholderKeys['inv_state'], key: 'inv_state', tag: 'table'},
                {label: placeholderKeys['inv_tarif_price'], key: 'inv_tarif_price', tag: 'table'},
                {label: placeholderKeys['inv_tarif_duration'], key: 'inv_tarif_duration', tag: 'table'},
                {label: placeholderKeys['inv_inventory_category'], key: 'inv_inventory_category', tag: 'table'},
                {label: placeholderKeys['inv_inventory_buy_price'], key: 'inv_inventory_buy_price', tag: 'table'},
                {label: placeholderKeys['inv_inventory_buy_date'], key: 'inv_inventory_buy_date', tag: 'table'},
                {label: placeholderKeys['inv_inventory_point_name'], key: 'inv_inventory_point_name', tag: 'table'},
                {label: placeholderKeys['inv_inventory_point_address'], key: 'inv_inventory_point_address', tag: 'table'},
                ...config.inventory.map(field => ({label: field.label, key: `inv_${field.name}`, tag: 'table'})),
                ...config.inventory_group.map(field => ({label: field.label, key: `inv_group_${field.name}`, tag: 'table'})),
            ],
        },
        {
            key: 'service',
            label: 'Услуги',
            children: [
                {label: placeholderKeys['service_index'], key: 'service_index', tag: 'table'},
                {label: placeholderKeys['service_id'], key: 'service_id', tag: 'table'},
                {label: placeholderKeys['service_name'], key: 'service_name', tag: 'table'},
                {label: placeholderKeys['service_price'], key: 'service_price', tag: 'table'},
                {label: placeholderKeys['service_price_discount'], key: 'service_price_discount', tag: 'table'},
                {label: placeholderKeys['service_extra_delivery_address'], key: 'service_extra_delivery_address', tag: 'table'},
                {label: placeholderKeys['service_info'], key: 'service_info', tag: 'table'},
                {label: placeholderKeys['service_tarif_price'], key: 'service_tarif_price', tag: 'table'},
                {label: placeholderKeys['service_tarif_duration'], key: 'service_tarif_duration', tag: 'table'},
                {label: placeholderKeys['service_worker'], key: 'service_worker', tag: 'table'},
            ],
        },
    ]
}

export const config: EditorConfig = {
    fontColor: {colors},
    toolbar: {
        items: [
            "sourceEditing",
            "heading",
            "alignment",
            "fontFamily",
            "fontSize",
            "lineHeight",
            "highlight",
            "fontColor",
            "fontBackgroundColor",
            "-",
            "style",
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "link",
            "bulletedList",
            "numberedList",
            "superscript",
            "subscript",
            "|",
            "outdent",
            "indent",
            "|",
            "imageUpload",
            "blockQuote",
            "insertTable",
            "specialCharacters",
            "removeFormat",
            "htmlEmbed",
            "showBlocks",
            "selectAll",
            "pageBreak",
            "undo",
            "redo",
        ],
    },
    style: {
        definitions: [
            {
                name: 'Без отсутупов',
                element: 'p',
                classes: ['m-0', 'p-0']
            },
        ]
    },
    fontBackgroundColor: {
        colors: colors,
        columns: 5,
    },
    fontSize: {
        options: ["8px", "10px", "11px", "12px", "14px", "16px", "18px", "21px", "24px", "32px", "40px"],
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
        tableCellProperties: {
            borderColors: colors,
            backgroundColors: colors,
            defaultProperties: {
                horizontalAlignment: 'center',
                verticalAlignment: 'center',
                padding: '4px'
            }
        },
        tableProperties: {
            borderColors: colors,
            backgroundColors: colors,
            defaultProperties: {
                alignment: 'left',
                borderCollapse: "collapse",
                width: '100%',
            }
        },
        defaultHeadings: {
            rows: 0,
            columns: 0,
        },
    },
    heading: {
        options: [
            {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
            {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
            {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
            {model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3'},
            {model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4'},
            {model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5'},
            {model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6'}
        ]
    },
    lineHeight: {
        options: [0.3, 0.5, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.5, 2, 2.5, 3]
    },
    placeholderKeys
}

export const baseConfig: EditorConfig = (lang: string = 'ru') => ({
    language: lang,
    fontColor: {colors},
    toolbar: {
        items: [
            "sourceEditing",
            "heading",
            "alignment",
            "fontFamily",
            "fontSize",
            "lineHeight",
            "highlight",
            "fontColor",
            "fontBackgroundColor",
            "-",
            "style",
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "link",
            "bulletedList",
            "numberedList",
            "superscript",
            "subscript",
            "|",
            "outdent",
            "indent",
            "|",
            "imageUpload",
            "blockQuote",
            "insertTable",
            "specialCharacters",
            "removeFormat",
            "pageBreak",
            "undo",
            "redo",
        ],
    },
})

export const getPrintStyles = (size: string = '210mm 297mm', margin: string = '2em') => `
    <style type="text/css">
    body {
        z-index: 100 !important;
        visibility: visible !important;
        position: relative !important;
        display: block !important;
        width: ${size.split(' ')[0]} !important;
        height: ${size.split(' ')[1]} !important;
        position: relative !important;
        padding: 0 !important;
        top: 0 !important;
        left: 0 !important;
        margin: auto !important;
        orphans: 0 !important;
        widows: 0!important;
        overflow: visible !important;
    }
    .ck-content .m-0 {
        margin: 0 !important;
    }
    .ck-content .m-0 {
        padding: 0 !important;
    }
    .ck-content .table > figcaption {
        display: table-caption;
        caption-side: top;
        word-break: break-word;
        text-align: center;
        color: var(--ck-color-table-caption-text);
        background-color: var(--ck-color-table-caption-background);
        padding: .6em;
        font-size: .75em;
        outline-offset: -1px;
    }
    .ck-content .table {
        margin: 4px auto;
        display: table;
    }
    .ck-content .table table {
        border-collapse: collapse;
        border-spacing: 0;
        border: 1px double transparent;
    }
    .ck-content .table table td,
    .ck-content .table table th {
        min-width: 2em;
        padding: .4em;
        border: 1px solid transparent;
    }
    .ck-content .table table th {
        font-weight: bold;
        background: #0000000d;
    }
    .ck-content[dir="rtl"] .table th {
        text-align: right;
    }
    .ck-content[dir="ltr"] .table th {
        text-align: left;
    }
    .ck-content .table .ck-table-resized {
        width: 100% !important;
        table-layout: fixed;
    }
    .ck-content .table table {
        width: 100% !important;
        table-layout: fixed;
        overflow: hidden;
    }
    .ck-content .table td,
    .ck-content .table th {
        overflow-wrap: break-word;
        position: relative;
    }
    .ck-content .media {
        clear: both;
        margin: 0.9em 0;
        display: block;
        min-width: 15em;
    }
    .ck-content .todo-list {
        list-style: none;
    }
    .ck-content .todo-list li {
        margin-bottom: 5px;
    }
    .ck-content .todo-list li .todo-list {
        margin-top: 5px;
    }
    .ck-content .todo-list .todo-list__label > input {
        -webkit-appearance: none;
        display: inline-block;
        position: relative;
        width: var(--ck-todo-list-checkmark-size);
        height: var(--ck-todo-list-checkmark-size);
        vertical-align: middle;
        border: 0;
        left: -25px;
        margin-right: -15px;
        right: 0;
        margin-left: 0;
    }
    .ck-content .todo-list .todo-list__label > input::before {
        display: block;
        position: absolute;
        box-sizing: border-box;
        content: "";
        width: 100%;
        height: 100%;
        border: 1px solid #333333;
        border-radius: 2px;
        transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
    }
    .ck-content .todo-list .todo-list__label > input::after {
        display: block;
        position: absolute;
        box-sizing: content-box;
        pointer-events: none;
        content: "";
        left: calc( var(--ck-todo-list-checkmark-size) / 3 );
        top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
        width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
        height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
        border-style: solid;
        border-color: transparent;
        border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
        transform: rotate(45deg);
    }
    .ck-content .todo-list .todo-list__label > input[checked]::before {
        background: #26ab33;
        border-color: #26ab33;
    }
    .ck-content .todo-list .todo-list__label > input[checked]::after {
        border-color: #ffffff;
    }
    .ck-content .todo-list .todo-list__label .todo-list__label__description {
        vertical-align: middle;
    }
    .ck-content .image {
        display: table;
        clear: both;
        text-align: center;
        margin: 0.9em auto;
        min-width: 50px;
    }
    .ck-content .image img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        min-width: 100%;
    }
    .ck-content .image-inline {
        display: inline-flex;
        max-width: 100%;
        align-items: flex-start;
    }
    .ck-content .image-inline picture {
        display: flex;
    }
    .ck-content .image-inline picture,
    .ck-content .image-inline img {
        flex-grow: 1;
        flex-shrink: 1;
        max-width: 100%;
    }
    .ck-content .image.image_resized {
        max-width: 100%;
        display: block;
        box-sizing: border-box;
    }
    .ck-content .image.image_resized img {
        width: 100%;
    }
    .ck-content .image.image_resized > figcaption {
        display: block;
    }
    .ck-content .image > figcaption {
        display: table-caption;
        caption-side: bottom;
        word-break: break-word;
        color: var(--ck-color-image-caption-text);
        background-color: var(--ck-color-image-caption-background);
        padding: .6em;
        font-size: .75em;
        outline-offset: -1px;
    }
    .ck-content .marker-yellow {
        background-color: var(--ck-highlight-marker-yellow);
    }
    .ck-content .marker-green {
        background-color: var(--ck-highlight-marker-green);
    }
    .ck-content .marker-pink {
        background-color: var(--ck-highlight-marker-pink);
    }
    .ck-content .marker-blue {
        background-color: var(--ck-highlight-marker-blue);
    }
    .ck-content .pen-red {
        color: var(--ck-highlight-pen-red);
        background-color: transparent;
    }
    .ck-content .pen-green {
        color: var(--ck-highlight-pen-green);
        background-color: transparent;
    }
    .ck-content ol {
        list-style-type: decimal;
    }
    .ck-content ol ol {
        list-style-type: lower-latin;
    }
    .ck-content ol ol ol {
        list-style-type: lower-roman;
    }
    .ck-content ol ol ol ol {
        list-style-type: upper-latin;
    }
    .ck-content ol ol ol ol ol {
        list-style-type: upper-roman;
    }
    .ck-content ul {
        list-style-type: disc;
    }
    .ck-content ul ul {
        list-style-type: circle;
    }
    .ck-content ul ul ul {
        list-style-type: square;
    }
    .ck-content ul ul ul ul {
        list-style-type: square;
    }
    .ck-content .image-style-block-align-left,
    .ck-content .image-style-block-align-right {
        max-width: calc(100% - var(--ck-image-style-spacing));
    }
    .ck-content .image-style-align-left,
    .ck-content .image-style-align-right {
        clear: none;
    }
    .ck-content .image-style-side {
        float: right;
        margin-left: var(--ck-image-style-spacing);
        max-width: 50%;
    }
    .ck-content .image-style-align-left {
        float: left;
        margin-right: var(--ck-image-style-spacing);
    }
    .ck-content .image-style-align-center {
        margin-left: auto;
        margin-right: auto;
    }
    .ck-content .image-style-align-right {
        float: right;
        margin-left: var(--ck-image-style-spacing);
    }
    .ck-content .image-style-block-align-right {
        margin-right: 0;
        margin-left: auto;
    }
    .ck-content .image-style-block-align-left {
        margin-left: 0;
        margin-right: auto;
    }
    .ck-content p + .image-style-align-left,
    .ck-content p + .image-style-align-right,
    .ck-content p + .image-style-side {
        margin-top: 0;
    }
    .ck-content .image-inline.image-style-align-left,
    .ck-content .image-inline.image-style-align-right {
        margin-top: var(--ck-inline-image-style-spacing);
        margin-bottom: var(--ck-inline-image-style-spacing);
    }
    .ck-content .image-inline.image-style-align-left {
        margin-right: var(--ck-inline-image-style-spacing);
    }
    .ck-content .image-inline.image-style-align-right {
        margin-left: var(--ck-inline-image-style-spacing);
    }
    .ck-content code {
        background-color: #c7c7c74d;
        padding: .15em;
        border-radius: 2px;
    }
    .ck-content blockquote {
        overflow: hidden;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin-left: 0;
        margin-right: 0;
        font-style: italic;
        border-left: solid 5px #cccccc;
    }
    .ck-content[dir="rtl"] blockquote {
        border-left: 0;
        border-right: solid 5px #cccccc;
    }
    .ck-content .text-tiny {
        font-size: .7em;
    }
    .ck-content .text-small {
        font-size: .85em;
    }
    .ck-content .text-big {
        font-size: 1.4em;
    }
    .ck-content .text-huge {
        font-size: 1.8em;
    }
    .ck-content .mention {
        background: var(--ck-color-mention-background);
        color: var(--ck-color-mention-text);
    }
    .ck-content hr {
        margin: 15px 0;
        height: 4px;
        background: #dedede;
        border: 0;
    }
    .ck-content pre {
        padding: 1em;
        color: #353535;
        background: #c7c7c74d;
        border: 1px solid #c4c4c4;
        border-radius: 2px;
        text-align: left;
        direction: ltr;
        tab-size: 4;
        white-space: pre-wrap;
        font-style: normal;
        min-width: 200px;
    }
    .ck-content pre code {
        background: unset;
        padding: 0;
        border-radius: 0;
    }
    @page {size:${size}; margin:${margin}; orphans:0!important;widows:0!important }
    </style>
`
