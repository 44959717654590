import axios from 'axios'
import {ApiAbstract} from '../abstract/api.abstract'
import {ManagerConstantModel} from 'src/models/manager/constants'
import {BehaviorSubject, Observable, from, map, shareReplay, switchMap} from 'rxjs'
import {axiosResponce} from '../interceptor/axios-response'

export class AppService extends ApiAbstract {
  constructor() {
    super('v1/crm')
  }

  private loadConstants$: BehaviorSubject<void> = new BehaviorSubject<void>(null)
  loadConstants = () => this.loadConstants$.next()

  constants$: Observable<ManagerConstantModel> = this.loadConstants$.pipe(
    switchMap(() => from(axios.get<ManagerConstantModel>(this.getUrl('constants'), {params: {skip_error_handling: true}})).pipe(axiosResponce)),
    shareReplay(1)
  )

  getConstant(key: keyof ManagerConstantModel): Observable<any> {
    return this.constants$.pipe(map(constants => constants[key]))
  }
}

const appService = new AppService()
export default appService
